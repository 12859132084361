import React from 'react';

import './footer.scss';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      company {
        name
        postal_code
        city
        address_line_1
        phone
      }
    }
  `);

  return (
    <div className="container-fluid footer">
      <div id="kontakt" />
      <div className="row center-xs start-sm between-sm">
        <div className="col-xs-12 col-sm">
          <div className="footer-logo">{data.company.name}</div>
          <div className="footer-text">{data.company.address_line_1}</div>
          <div className="footer-text">
            {data.company.postal_code}
            {' '}
            {data.company.city}
          </div>
          <div className="footer-text">{data.company.phone}</div>
        </div>
        <div className="col-xs-12 col-sm footer-links">
          <a href="/impressum" className="footer-link">
            IMPRESSUM
          </a>
          <a href="/datenschutz" className="footer-link">
            DATENSCHUTZ
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
