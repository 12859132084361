import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

import './layout.scss';

const Layout = ({ children }) => {
  const { company } = useStaticQuery(graphql`
    query {
      company {
        name
        description
      }
    }
  `);

  return (
    <div>
      <Helmet>
        <title>{company.name}</title>
        <meta name="description" content={company.description} />
        <html lang="de" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
