import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Scrollspy from 'react-scrollspy';

import './header.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      company {
        name
        instagram_page
        logo {
          childImageSharp {
            fixed(width: 125) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
          name
        }
      }
      modules {
        partners
        services
        projects
      }
      instagram: file(relativePath: { eq: "instagram-icon.png" }) {
        childImageSharp {
          fixed(width: 16, height: 16) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const { modules, company } = data;

  const scrollOrGo = (e, anchor) => {
    if (window.location.pathname === '/') {
      e.preventDefault();
      scrollTo(`#${anchor}`);
    }
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row header-row center-xs middle-xs between-sm">
          <div
            className="col-xs col-sm"
            style={{ flexGrow: 0, height: '100%' }}
          >
            <div
              className="header-logo"
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <a href="/" onClick={(e) => scrollOrGo(e, 'start')}>
                <Img
                  fixed={company.logo.childImageSharp.fixed}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt={company.logo.name}
                />
              </a>
            </div>
          </div>
          <div className="col-xs header-menu">
            <div className="row end-xs center-md">
              <Scrollspy
                componentTag="a"
                items={[
                  'start',
                  'projekte',
                  'leistungen',
                  'partner',
                  'kontakt',
                ]}
                offset={500}
                currentClassName="header-link-active"
              >
                <a
                  href="/"
                  onClick={(e) => {
                    scrollOrGo(e, 'start');
                  }}
                  className="header-link"
                >
                  START
                </a>
                {modules.projects && (
                  <a
                    href="/#projekte"
                    onClick={(e) => {
                      scrollOrGo(e, 'projekte');
                    }}
                    className="header-link"
                  >
                    PROJEKTE
                  </a>
                )}
                {modules.services && (
                  <a
                    href="/#leistungen"
                    onClick={(e) => {
                      scrollOrGo(e, 'leistungen');
                    }}
                    className="header-link"
                  >
                    LEISTUNGEN
                  </a>
                )}
                {modules.partners && (
                  <a
                    href="/#partner"
                    onClick={(e) => {
                      scrollOrGo(e, 'partner');
                    }}
                    className="header-link"
                  >
                    PARTNER
                  </a>
                )}
                <a
                  href="/#kontakt"
                  onClick={(e) => {
                    scrollOrGo(e, 'kontakt');
                  }}
                  className="header-link"
                >
                  KONTAKT
                </a>
              </Scrollspy>
            </div>
          </div>
          {company.instagram_page && (
            <div className="col-xs header-instagram" style={{ flexGrow: 0 }}>
              <div className="row end-xs">
                <Img
                  fixed={data.instagram.childImageSharp.fixed}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt="Instagram icon"
                />
              </div>
              <a
                className="header-instagram-page"
                href={company.instagram_page}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
